.RetrievalApp {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }

  .search-box {
    margin-bottom: 20px;
  }

  .search-input {
    padding: 10px;
    margin-right: 10px;
    width: 80%;
  }

  .search-button {
    padding: 10px 20px;
    cursor: pointer;
  }

  .results {
    margin-top: 20px;
  }

  .result-item {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 10px;
  }

  .result-title {
    font-weight: bold;
  }

  .result-content {
    margin: 10px 0;
  }

  .result-link {
    color: #06c;
    text-decoration: none;
  }

  .result-score {
    font-style: italic;
  }

  .error {
    color: red;
    margin: 10px 0;
  }

  .footer {
    margin-top: 30px;
    font-size: 0.8em;
    text-align: center;
  }
